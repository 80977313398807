import React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"

const name = "A Jacob"
const street = "Richard~Wagner~Strasse 8"
const city = "68165 Mannheim, Germany"

const Imprint = () => (
  <Layout>
    <SEO title="Impressum" />
    <h1>Impressum</h1>
    <p>Dieses Impressum gilt für die private Webseite “carlgustavjacob.com”,
      deren Inhalte in Eigenleistung erstellt werden.
    </p>

    <p>
      {name} <br />
      {street} <br />
      {city}
    </p>

    <p>
      Tel.: (auf Nachfrage) <br />
      E-Mail: imprint-carl-2020@cordobo.de<br />
    </p>
    <p>
      <Link to="/">&laquo; Zurück zur Startseite</Link>
    </p>
  </Layout >
)

export default Imprint
